import * as React from "react";
import { createModule } from "../config.js";
import { cleanup, clsx, cssClass, cssVar, isNumber, makeComposePrefix, makeUseContext, parseLengthPercentage, } from "../utils.js";
import { useContainerRect, useEventCallback, useForkRef, useLayoutEffect, useMotionPreference, useRTL, useSensors, } from "../hooks/index.js";
import { useEvents, useLightboxState, useTimeouts } from "../contexts/index.js";
import { SwipeState, usePointerSwipe, usePreventSwipeNavigation, useWheelSwipe } from "./controller/index.js";
import { ACTION_CLOSE, ACTION_NEXT, ACTION_PREV, CLASS_FLEX_CENTER, EVENT_ON_KEY_UP, MODULE_CONTROLLER, VK_ESCAPE, YARL_EVENT_BACKDROP_CLICK, } from "../consts.js";
const cssContainerPrefix = makeComposePrefix("container");
const ControllerContext = React.createContext(null);
export const useController = makeUseContext("useController", "ControllerContext", ControllerContext);
export const Controller = ({ children, ...props }) => {
    const { carousel, slides, animation, controller, on, styles } = props;
    const { state, dispatch } = useLightboxState();
    const [swipeState, setSwipeState] = React.useState(SwipeState.NONE);
    const swipeOffset = React.useRef(0);
    const swipeAnimationReset = React.useRef();
    const { registerSensors, subscribeSensors } = useSensors();
    const { subscribe, publish } = useEvents();
    const { setTimeout, clearTimeout } = useTimeouts();
    const { containerRef, setContainerRef, containerRect } = useContainerRect();
    const handleContainerRef = useForkRef(usePreventSwipeNavigation(), setContainerRef);
    const carouselRef = React.useRef(null);
    const setCarouselRef = useForkRef(carouselRef, undefined);
    const carouselAnimation = React.useRef();
    const carouselSwipeAnimation = React.useRef();
    const reduceMotion = useMotionPreference();
    const isRTL = useRTL();
    const rtl = useEventCallback((value) => (isRTL ? -1 : 1) * (isNumber(value) ? value : 1));
    const isSwipeValid = useEventCallback((offset) => !(carousel.finite &&
        ((rtl(offset) > 0 && state.currentIndex === 0) ||
            (rtl(offset) < 0 && state.currentIndex === slides.length - 1))));
    const setSwipeOffset = React.useCallback((offset) => {
        var _a;
        swipeOffset.current = offset;
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty(cssVar("swipe_offset"), `${Math.round(offset)}px`);
    }, [containerRef]);
    const swipe = useEventCallback((action) => {
        var _a;
        const swipeDuration = animation.swipe;
        const currentSwipeOffset = action.offset || 0;
        let { direction } = action;
        const count = (_a = action.count) !== null && _a !== void 0 ? _a : 1;
        let newSwipeState = SwipeState.ANIMATION;
        let newSwipeAnimationDuration = swipeDuration * count;
        if (!direction) {
            const containerWidth = containerRect === null || containerRect === void 0 ? void 0 : containerRect.width;
            const elapsedTime = action.duration || 0;
            const expectedTime = containerWidth
                ? (swipeDuration / containerWidth) * Math.abs(currentSwipeOffset)
                : swipeDuration;
            if (count !== 0) {
                if (elapsedTime < expectedTime) {
                    newSwipeAnimationDuration =
                        (newSwipeAnimationDuration / expectedTime) * Math.max(elapsedTime, expectedTime / 5);
                }
                else if (containerWidth) {
                    newSwipeAnimationDuration =
                        (swipeDuration / containerWidth) * (containerWidth - Math.abs(currentSwipeOffset));
                }
            }
            else {
                newSwipeAnimationDuration = swipeDuration / 2;
            }
            if (count !== 0) {
                direction = rtl(currentSwipeOffset) > 0 ? ACTION_PREV : ACTION_NEXT;
            }
        }
        let increment;
        if (direction === ACTION_PREV) {
            if (isSwipeValid(rtl(1))) {
                increment = -count;
            }
            else {
                newSwipeState = SwipeState.NONE;
                newSwipeAnimationDuration = swipeDuration;
            }
        }
        else if (direction === ACTION_NEXT) {
            if (isSwipeValid(rtl(-1))) {
                increment = count;
            }
            else {
                newSwipeState = SwipeState.NONE;
                newSwipeAnimationDuration = swipeDuration;
            }
        }
        if (carouselRef.current) {
            carouselSwipeAnimation.current = {
                rect: carouselRef.current.getBoundingClientRect(),
                index: state.globalIndex,
            };
        }
        newSwipeAnimationDuration = Math.round(newSwipeAnimationDuration);
        clearTimeout(swipeAnimationReset.current);
        if (newSwipeState) {
            const timeoutId = setTimeout(() => {
                if (swipeAnimationReset.current === timeoutId) {
                    setSwipeOffset(0);
                    setSwipeState(SwipeState.NONE);
                }
            }, newSwipeAnimationDuration);
            swipeAnimationReset.current = timeoutId;
        }
        setSwipeState(newSwipeState);
        dispatch({ increment, animationDuration: newSwipeAnimationDuration });
    });
    const animateCarouselSwipe = useEventCallback(() => {
        var _a, _b, _c;
        const swipeAnimation = carouselSwipeAnimation.current;
        carouselSwipeAnimation.current = undefined;
        if (swipeAnimation && carouselRef.current && containerRect) {
            const parsedSpacing = parseLengthPercentage(carousel.spacing);
            const spacingValue = (parsedSpacing.percent ? (parsedSpacing.percent * containerRect.width) / 100 : parsedSpacing.pixel) ||
                0;
            (_a = carouselAnimation.current) === null || _a === void 0 ? void 0 : _a.cancel();
            carouselAnimation.current = (_c = (_b = carouselRef.current).animate) === null || _c === void 0 ? void 0 : _c.call(_b, [
                {
                    transform: `translateX(${rtl(state.globalIndex - swipeAnimation.index) * (containerRect.width + spacingValue) +
                        swipeAnimation.rect.x -
                        carouselRef.current.getBoundingClientRect().x}px)`,
                },
                { transform: "translateX(0)" },
            ], !reduceMotion ? state.animationDuration : 0);
            if (carouselAnimation.current) {
                carouselAnimation.current.onfinish = () => {
                    carouselAnimation.current = undefined;
                };
            }
        }
    });
    useLayoutEffect(animateCarouselSwipe);
    const swipeParams = [
        subscribeSensors,
        isSwipeValid,
        (containerRect === null || containerRect === void 0 ? void 0 : containerRect.width) || 0,
        animation.swipe,
        () => setSwipeState(SwipeState.SWIPE),
        (offset) => setSwipeOffset(offset),
        (offset, duration) => swipe({ offset, duration, count: 1 }),
        (offset) => swipe({ offset, count: 0 }),
    ];
    usePointerSwipe(...swipeParams);
    useWheelSwipe(swipeState, ...swipeParams);
    const focusOnMount = useEventCallback(() => {
        var _a;
        if (controller.focus) {
            (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    });
    React.useEffect(focusOnMount, [focusOnMount]);
    const handleIndexChange = useEventCallback(() => {
        var _a;
        (_a = on.view) === null || _a === void 0 ? void 0 : _a.call(on, state.currentIndex);
    });
    React.useEffect(handleIndexChange, [state.currentIndex, handleIndexChange]);
    React.useEffect(() => cleanup(subscribe(ACTION_PREV, (count) => swipe({
        direction: ACTION_PREV,
        count: isNumber(count) ? count : undefined,
    })), subscribe(ACTION_NEXT, (count) => swipe({
        direction: ACTION_NEXT,
        count: isNumber(count) ? count : undefined,
    }))), [subscribe, swipe]);
    React.useEffect(() => subscribeSensors(EVENT_ON_KEY_UP, (event) => {
        if (event.code === VK_ESCAPE) {
            publish(ACTION_CLOSE);
        }
    }), [subscribeSensors, publish]);
    React.useEffect(() => controller.closeOnBackdropClick
        ? subscribe(YARL_EVENT_BACKDROP_CLICK, () => publish(ACTION_CLOSE))
        : () => { }, [controller.closeOnBackdropClick, publish, subscribe]);
    const transferFocus = useEventCallback(() => { var _a; return (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus(); });
    const getLightboxProps = useEventCallback(() => props);
    const context = React.useMemo(() => ({
        getLightboxProps,
        subscribeSensors,
        transferFocus,
        containerRect: containerRect || { width: 0, height: 0 },
        containerRef,
        setCarouselRef,
    }), [getLightboxProps, subscribeSensors, transferFocus, containerRect, containerRef, setCarouselRef]);
    return (React.createElement("div", { ref: handleContainerRef, className: clsx(cssClass(cssContainerPrefix()), cssClass(CLASS_FLEX_CENTER)), style: {
            ...(swipeState === SwipeState.SWIPE
                ? { [cssVar("swipe_offset")]: `${Math.round(swipeOffset.current)}px` }
                : null),
            ...(controller.touchAction !== "none"
                ? { [cssVar("controller_touch_action")]: controller.touchAction }
                : null),
            ...styles.container,
        }, ...(controller.aria ? { role: "presentation", "aria-live": "polite" } : null), tabIndex: -1, ...registerSensors }, containerRect && React.createElement(ControllerContext.Provider, { value: context }, children)));
};
export const ControllerModule = createModule(MODULE_CONTROLLER, Controller);
