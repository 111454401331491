{
  "lang_markread": "Marker som lest",
  "lang_newest": "Nyeste",
  "lang_unread": "Ulest",
  "lang_starred": "Stjernemerket",
  "lang_tags": "Etiketter",
  "lang_alltags": "Alle etiketter",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "for {0,plural,one {én time},other {# timer}} ago",
  "lang_minutes": "for {0,plural,one {ett minutt},other {# minutter}} ago",
  "lang_seconds": "for {0,plural,one {ett sekund},other {# sekunder}} siden",
  "lang_star": "Stjernemerk",
  "lang_unstar": "Fjern stjerne",
  "lang_mark": "Marker som lest",
  "lang_unmark": "Marker som ulest",
  "lang_load_img": "Vis bilder",
  "lang_open_window": "Åpne",
  "lang_searchbutton": "Søk",
  "lang_sources": "Kilder",
  "lang_source_add": "Legg til kilde",
  "lang_source_opml": "eller importer en OPML-fil",
  "lang_source_export": "Eksporter kilder",
  "lang_source_edit": "Endre",
  "lang_source_delete": "Slett",
  "lang_source_warn": "Vil du virkelig fjerne denne kilden?",
  "lang_source_new": "Ny ulagret kilde",
  "lang_source_title": "Tittel",
  "lang_source_tags": "Etiketter",
  "lang_source_comma": "Kommainndelt",
  "lang_source_select": "Velg kilde",
  "lang_source_type": "Type",
  "lang_source_save": "Lagre",
  "lang_source_cancel": "Avbryt",
  "lang_source_saved": "Lagret",
  "lang_no_entries": "Ingen data funnet",
  "lang_more": "Mer",
  "lang_login": "Logg inn",
  "lang_login_username": "Brukernavn",
  "lang_login_password": "Passord",
  "lang_filter": "Filtrer",
  "lang_days": "{0,plural,zero {i dag},one {i går},other {# dager siden}}",
  "lang_next": "Neste",
  "lang_searchremove": "Skjul søk",
  "lang_refreshbutton": "Gjenoppfrisk kilder",
  "lang_settingsbutton": "Innstillinger",
  "lang_logoutbutton": "Logg ut",
  "lang_loginbutton": "Logg inn",
  "lang_source_filter": "Filter",
  "lang_source_autotitle_hint": "La stå tom for å hente tittel",
  "lang_source_pwd_placeholder": "Ikke endret",
  "lang_source_last_post": "Siste post sett",
  "lang_source_refresh": "Gjenoppfrisk denne kilden",
  "lang_login_invalid_credentials": "Feil brukernavn/passord",
  "lang_no_title": "Ingen tittel",
  "lang_error": "En feil inntraff",
  "lang_streamerror": "Ingen elementer ble lastet inn, gjeninnlast?",
  "lang_reload_list": "Gjeninnlast liste",
  "lang_sources_refreshed": "Kilder gjeninnlastet",
  "lang_error_session_expired": "Økt utløpt",
  "lang_error_add_source": "Kunne ikke legge til kilde:",
  "lang_error_delete_source": "Kunne ikke slette kilde:",
  "lang_error_load_tags": "Kunne ikke laste inn etiketter:",
  "lang_error_unknown_tag": "Ukjent etikett:",
  "lang_error_unknown_source": "Ukjent kilde-ID:",
  "lang_error_mark_items": "Kan ikke merke alle synlige elementer:",
  "lang_error_mark_item": "Kan ikke merke/oppheve merking av element:",
  "lang_error_star_item": "Kan ikke stjernemerke/oppheve stjernemerking av element:",
  "lang_error_logout": "Kunne ikke logge ut:",
  "lang_error_loading": "Innlastingsfeil:",
  "lang_error_loading_stats": "Kan ikke laste navigeringsstatistikk:",
  "lang_error_saving_color": "Kan ikke lagre ny farge:",
  "lang_error_refreshing_source": "Kan ikke gjenoppfriske kilder:",
  "lang_error_sync": "Kunne ikke synkronisere siste endringer fra tjener:",
  "lang_error_invalid_subsection": "Ugyldig underdel:",
  "lang_online_count": "Elementer tilgjengelige på tjeneren",
  "lang_offline_count": "Elementer tilgjengelige lokalt",
  "lang_login_offline": "Frakoblet lagring",
  "lang_app_update": "selfoss har blitt oppdatert, last inn på ny",
  "lang_app_reload": "Gjeninnlast",
  "lang_error_offline_storage": "Lagringsfeil for frakoblet lagring: {0}. Gjeninnlasting kan hjelpe. Skrur av frakoblet imens.",
  "lang_search_label": "Søketekst",
  "lang_article_actions": "Handlinger",
  "lang_error_configuration": "Kunne ikke laste inn oppsett.",
  "lang_info_url_copied": "Nettadresse kopiert til utklippstavle.",
  "lang_share_native_label": "Del",
  "lang_share_diaspora_label": "Del på Diaspora",
  "lang_share_twitter_label": "Del på Twitter",
  "lang_share_facebook_label": "Del på Facebook",
  "lang_share_pocket_label": "Del til Pocket",
  "lang_share_wallabag_label": "Lagre på Wallabag",
  "lang_share_wordpress_label": "Lagre på WordPress",
  "lang_share_mail_label": "Del ved bruk av e-post",
  "lang_share_copy_label": "Kopier lenke til utklippstavle",
  "lang_error_share_native_abort": "Kunne ikke dele element, enten finnes det ingen delingsmål, eller du har avbrutt deling.",
  "lang_error_share_native": "Kunne ikke dele element.",
  "lang_close_entry": "Lukk",
  "lang_error_unwritable_logs": "Kunne ikke loggføre.",
  "lang_error_check_log_file": "En feil inntraff, sjekk loggfilen “{0}”.",
  "lang_error_check_system_logs": "En feil inntraff, sjekk systemloggene dine.",
  "lang_offline_navigation_unavailable": "Å bytte etiketter og kilder støttes foreløpig ikke i frakoblet modus.",
  "lang_error_offline_storage_not_available": "Frakoblet lager ikke tilgjengelig. Sørg for at din nettleser {0}støtter IndexedDB-API-et{1}, samt at du ikke bruker incognito-modus i en Chrome-basert nettleser.",
  "lang_error_edit_source": "Kunne ikke lagre kildeendringer:",
  "lang_article_reading_time": "{0} min å lese",
  "lang_experimental": "Eksperimentell",
  "lang_sources_leaving_unsaved_prompt": "Forlat innstillinger med ulagrede kildeendringer?",
  "lang_source_warn_cancel_dirty": "Forkast ulagrede endringer?"
}
