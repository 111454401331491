import * as React from "react";
import { createModule } from "../config.js";
import { composePrefix, cssClass, label } from "../utils.js";
import { useEvents } from "../contexts/index.js";
import { CloseIcon, IconButton } from "../components/index.js";
import { useContainerRect } from "../hooks/useContainerRect.js";
import { ACTION_CLOSE, MODULE_TOOLBAR } from "../consts.js";
const cssPrefix = (value) => composePrefix(MODULE_TOOLBAR, value);
export const Toolbar = ({ toolbar: { buttons }, labels, render: { buttonClose, iconClose } }) => {
    const { publish } = useEvents();
    const { setContainerRef, containerRect } = useContainerRect();
    React.useEffect(() => {
        if (containerRect === null || containerRect === void 0 ? void 0 : containerRect.width) {
            publish("toolbar-width", containerRect.width);
        }
    }, [publish, containerRect === null || containerRect === void 0 ? void 0 : containerRect.width]);
    const renderCloseButton = () => buttonClose ? (buttonClose()) : (React.createElement(IconButton, { key: ACTION_CLOSE, label: label(labels, "Close"), icon: CloseIcon, renderIcon: iconClose, onClick: () => publish(ACTION_CLOSE) }));
    return (React.createElement("div", { ref: setContainerRef, className: cssClass(cssPrefix()) }, buttons === null || buttons === void 0 ? void 0 : buttons.map((button) => (button === ACTION_CLOSE ? renderCloseButton() : button))));
};
export const ToolbarModule = createModule(MODULE_TOOLBAR, Toolbar);
