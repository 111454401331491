{
  "lang_markread": "пометить как прочитанные",
  "lang_newest": "свежие",
  "lang_unread": "непрочитанные",
  "lang_starred": "отмеченные",
  "lang_tags": "Метки",
  "lang_alltags": "все метки",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 час},other {# часов}} назад",
  "lang_minutes": "{0,plural,one {1 минуту},other {# минут}} назад",
  "lang_seconds": "{0,plural,one {1 секунду},other {# секунд}} назад",
  "lang_star": "отметить",
  "lang_unstar": "снять отметку",
  "lang_mark": "прочитано",
  "lang_unmark": "не прочитано",
  "lang_load_img": "загрузить изображения",
  "lang_open_window": "открыть",
  "lang_searchbutton": "Поиск",
  "lang_sources": "Источники",
  "lang_source_add": "добавить источник",
  "lang_source_opml": "или импортировать файл OPML, например из Google Reader",
  "lang_source_export": "экспорт источников",
  "lang_source_edit": "править",
  "lang_source_delete": "удалить",
  "lang_source_warn": "действительно удалить этот источник?",
  "lang_source_new": "новый несохраненный источник",
  "lang_source_title": "Название",
  "lang_source_tags": "Метки",
  "lang_source_comma": "раделяйте запятой",
  "lang_source_select": "выберите тип источника",
  "lang_source_type": "Тип",
  "lang_source_save": "сохранить",
  "lang_source_cancel": "отмена",
  "lang_source_saved": "сохранено",
  "lang_no_entries": "записей не найдено",
  "lang_more": "еще",
  "lang_login": "вход",
  "lang_login_username": "имя",
  "lang_login_password": "пароль",
  "lang_no_title": "без названия",
  "lang_searchremove": "Скрыть поиск"
}
