{
  "lang_error_offline_storage_not_available": "האחסון הבלתי מקוון אינו זמין. נא לוודא שהדפדפן שלך {0}תומך ב־API מסוג IndexedDB{1} וגם, אם הדפדפן שמשמש אותך מבוסס Google Chrome, שזאת אינה גלישה בסתר.",
  "lang_error_invalid_subsection": "תת־סעיף שגוי:",
  "lang_error_offline_storage": "שגיאת אחסון בלתי מקוון: {0}. אולי רענון יעזור. המצב הבלתי מקוון מושבת כרגע.",
  "lang_error_share_native_abort": "לא ניתן לשתף פריט - או שאין יעד לשיתוף או שביטלת.",
  "lang_error_configuration": "לא ניתן לטעון הגדרות.",
  "lang_error_share_native": "לא ניתן לשתף פריט.",
  "lang_article_actions": "פעולות",
  "lang_close_entry": "סגירה",
  "lang_info_url_copied": "הכתובת הועתקה ללוח הגזירים.",
  "lang_error_sync": "לא ניתן לסנכרן את השינויים האחרונים מהשרת:",
  "lang_error_refreshing_source": "לא ניתן לרענן מקורות:",
  "lang_error_saving_color": "לא ניתן לשמור צבע חדש:",
  "lang_error_loading_stats": "לא ניתן לטעון סטטיסטיקת ניווט:",
  "lang_error_loading": "שגיאת טעינה:",
  "lang_error_logout": "לא ניתן לצאת:",
  "lang_error_star_item": "לא ניתן לסמן בכוכב/לבטל כוכב של פריט:",
  "lang_error_mark_item": "לא ניתן לסמן/לבטל סימון של פריט:",
  "lang_error_mark_items": "לא ניתן לסמן את כל הפריטים הגלויים:",
  "lang_error_unknown_source": "מזהה המקור לא ידוע:",
  "lang_error_unknown_tag": "תגית לא ידועה:",
  "lang_error_load_tags": "לא ניתן לטעון תגיות:",
  "lang_error_delete_source": "לא ניתן למחוק מקור:",
  "lang_error_edit_source": "לא ניתן לשמור את שינויי המקור:",
  "lang_error_add_source": "לא ניתן להוסיף מקור:",
  "lang_error_session_expired": "תוקף ההפעלה פג",
  "lang_app_reload": "רענון",
  "lang_app_update": "selfoss התעדכן, נא לרענן",
  "lang_sources_refreshed": "המקורות התרעננו",
  "lang_reload_list": "לרענן רשימה",
  "lang_streamerror": "לא נטענו פריטים, לרענן?",
  "lang_error_unwritable_logs": "לא ניתן לכתוב יומנים.",
  "lang_error_check_system_logs": "אירעה שגיאה, נא לעיין ביומני המערכת שלך.",
  "lang_error_check_log_file": "אירעה שגיאה, נא לעיין בקובץ היומן „{0}”.",
  "lang_error": "אירעה שגיאה",
  "lang_no_title": "אין כותרת",
  "lang_login_offline": "אחסון בלתי מקוון",
  "lang_login_invalid_credentials": "שם משתמש/ססמה שגויים",
  "lang_login_password": "ססמה",
  "lang_login_username": "שם משתמש",
  "lang_login": "כניסה",
  "lang_more": "עוד",
  "lang_no_entries": "לא נמצאו רשומות",
  "lang_source_refresh": "לרענן את המקור הזה",
  "lang_source_last_post": "הרשומה האחרונה שנראתה",
  "lang_source_saved": "נשמר",
  "lang_source_cancel": "ביטול",
  "lang_source_save": "שמירה",
  "lang_source_type": "סוג",
  "lang_source_select": "נא לבחור מקור",
  "lang_source_comma": "עם הפרדה בפסיקים",
  "lang_source_pwd_placeholder": "לא השתנה",
  "lang_source_tags": "תגיות",
  "lang_source_autotitle_hint": "יש להשאיר ריק כדי לקבל את הכותרת",
  "lang_source_title": "כותרת",
  "lang_source_new": "מקור חדש שלא נשמר",
  "lang_source_warn": "למחוק את המקור הזה?",
  "lang_source_delete": "מחיקה",
  "lang_source_filter": "מסנן",
  "lang_source_edit": "עריכה",
  "lang_source_export": "ייצוא מקורות",
  "lang_source_opml": "או לייבא קובץ OPML",
  "lang_source_add": "הוספת מקור",
  "lang_sources": "מקורות",
  "lang_loginbutton": "כניסה",
  "lang_logoutbutton": "יציאה",
  "lang_settingsbutton": "הגדרות",
  "lang_refreshbutton": "לרענן מקורות",
  "lang_searchremove": "הסתרת חיפוש",
  "lang_searchbutton": "חיפוש",
  "lang_search_label": "ביטוי לחיפוש",
  "lang_share_copy_label": "העתקת קישור ללוח גזירים",
  "lang_share_mail_label": "שיתוף בדוא״ל",
  "lang_share_wordpress_label": "שיתוף בוורדפרס",
  "lang_share_wallabag_label": "שמירה ב־Wallabag",
  "lang_share_pocket_label": "שמירה ב־Pocket",
  "lang_share_facebook_label": "שיתוף בפייסבוק",
  "lang_share_twitter_label": "שיתוף בטוויטר",
  "lang_share_diaspora_label": "שיתוף ב־Diaspora",
  "lang_share_native_label": "שיתוף",
  "lang_next": "הבא",
  "lang_open_window": "פתיחה",
  "lang_load_img": "הצגת תמונות",
  "lang_unmark": "סימון שלא נקרא",
  "lang_mark": "סימון שנקרא",
  "lang_unstar": "ביטול כוכב",
  "lang_star": "לסמן בכוכב",
  "lang_days": "{0,plural,zero {היום},one {אתמול},two {שלשום},other {לפני # ימים}}",
  "lang_seconds": "לפני {0,plural,one {שנייה},other {# שניות}}",
  "lang_minutes": "לפני {0,plural,one {דקה},other {# דקות}}",
  "lang_hours": "לפני {0,plural,one {שעה},two {שעתיים},other {# שעות}}",
  "lang_timestamp": "‎{0,date} {0,time}",
  "lang_alltags": "כל התגיות",
  "lang_tags": "תגיות",
  "lang_offline_navigation_unavailable": "החלפת תגיות ומקורות אינה זמינה כרגע במצב בלתי מקוון.",
  "lang_offline_count": "פריטים זמינים מקומית",
  "lang_online_count": "פריטים זמינים בשרת",
  "lang_starred": "מסומן בכוכב",
  "lang_unread": "לא נקרא",
  "lang_newest": "החדש ביותר",
  "lang_filter": "מסנן",
  "lang_markread": "סימון שנקרא",
  "lang_article_reading_time": "{0} דקות קריאה",
  "lang_experimental": "ניסיוני",
  "lang_source_warn_cancel_dirty": "להשליך את השינויים שלא נשמרו?",
  "lang_sources_leaving_unsaved_prompt": "לצאת מההגדרות עם שינויים במקור שלא נשמרו?",
  "lang_source_browse": "עיון",
  "lang_source_menu": "פעולות נוספות",
  "lang_tag_change_color_button_title": "החלפת צבע",
  "lang_source_go_to_settings": "פתיחה בהגדרות",
  "lang_sources_loading": "המקורות נטענים…",
  "lang_no_sources": "לא הוגדרו מקורות.",
  "lang_source_deleting": "המקור נמחק…",
  "lang_source_params_loading": "המשתנים נטענים…",
  "lang_source_saving": "השינויים למקור נשמרים…",
  "lang_entries_loading": "הרשומות נטענות…",
  "lang_login_in_progress": "מתבצע ניסיון כניסה…",
  "lang_error_unauthorized": "נא {link_begin}להיכנס{link_end} כדי להמשיך.",
  "lang_login_error_generic": "לא ניתן להיכנס: {errorMessage}"
}
