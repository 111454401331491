"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depsAreEqual = depsAreEqual;

function depsAreEqual(prevDeps, deps) {
  return prevDeps.length === deps.length && deps.every(function (dep, index) {
    return Object.is(dep, prevDeps[index]);
  });
}