"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFunction = isFunction;

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(input) {
  return typeof input === "function";
}