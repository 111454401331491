import * as React from "react";
import { makeUseContext } from "../utils.js";
const LightboxStateContext = React.createContext(null);
export const useLightboxState = makeUseContext("useLightboxState", "LightboxStateContext", LightboxStateContext);
const reducer = (slidesCount) => (state, action) => {
    var _a;
    return action.increment !== undefined || action.animationDuration !== undefined
        ? {
            currentIndex: (((state.currentIndex + (action.increment || 0)) % slidesCount) + slidesCount) % slidesCount,
            globalIndex: state.globalIndex + (action.increment || 0),
            animationDuration: (_a = action.animationDuration) !== null && _a !== void 0 ? _a : state.animationDuration,
        }
        : state;
};
export const LightboxStateProvider = ({ initialIndex, slidesCount, children, }) => {
    const [state, dispatch] = React.useReducer(reducer(slidesCount), {
        currentIndex: initialIndex,
        globalIndex: initialIndex,
        animationDuration: 0,
    });
    const context = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
    return React.createElement(LightboxStateContext.Provider, { value: context }, children);
};
