{
  "lang_markread": "全部标记为已读",
  "lang_newest": "最新条目",
  "lang_unread": "未读条目",
  "lang_starred": "加星标的条目",
  "lang_tags": "标签",
  "lang_alltags": "所有标签",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 小时},other {# 小时}}前",
  "lang_minutes": "{0,plural,one {1 分钟},other {# 分钟}}前",
  "lang_seconds": "{0,plural,one {1 秒},other {# 秒}}前",
  "lang_days": "{0,plural,zero {今天},one {昨天},other {# 天前}}",
  "lang_star": "加星标",
  "lang_unstar": "取消星标",
  "lang_mark": "标记为已读",
  "lang_unmark": "标记为未读",
  "lang_load_img": "加载图片",
  "lang_open_window": "打开",
  "lang_searchbutton": "搜索",
  "lang_sources": "源",
  "lang_source_add": "添加源",
  "lang_source_opml": "或导入 OPML 文件/Google 阅读器订阅",
  "lang_source_export": "导出源",
  "lang_source_edit": "编辑",
  "lang_source_delete": "删除",
  "lang_source_warn": "确认要删除该源?",
  "lang_source_new": "新的源",
  "lang_source_title": "标题",
  "lang_source_tags": "标签",
  "lang_source_comma": "以逗号分隔",
  "lang_source_select": "请选择源",
  "lang_source_type": "类型",
  "lang_source_save": "保存",
  "lang_source_cancel": "取消",
  "lang_source_saved": "已保存",
  "lang_source_last_post": "更新于",
  "lang_no_entries": "没有条目",
  "lang_more": "更多",
  "lang_login": "登录",
  "lang_login_username": "用户名",
  "lang_login_password": "密码",
  "lang_no_title": "无标题",
  "lang_logoutbutton": "登出",
  "lang_loginbutton": "登录",
  "lang_error_logout": "不能登出：",
  "lang_settingsbutton": "设置",
  "lang_app_reload": "再启动",
  "lang_error_session_expired": "会话已过期",
  "lang_close_entry": "关闭",
  "lang_error_sync": "无法从服务器同步新的更改：",
  "lang_share_pocket_label": "保存到 Pocket",
  "lang_searchremove": "隐藏搜索",
  "lang_error_share_native_abort": "无法分享条目 - 可能是缺少分享对象或您已取消分享。",
  "lang_error_mark_items": "无法标记所有可见项目：",
  "lang_share_twitter_label": "分享到 Twitter",
  "lang_share_wallabag_label": "保存到 Wallabag",
  "lang_filter": "筛选",
  "lang_online_count": "服务器可用的条目",
  "lang_offline_navigation_unavailable": "离线模式下暂时不能切换标签和来源。",
  "lang_source_menu": "更多操作",
  "lang_source_browse": "浏览",
  "lang_offline_count": "本地可用的条目",
  "lang_next": "下一个",
  "lang_share_native_label": "分享",
  "lang_refreshbutton": "更新数据源",
  "lang_share_diaspora_label": "分享到 Diaspora",
  "lang_share_wordpress_label": "分享到 WordPress",
  "lang_search_label": "搜索词",
  "lang_share_mail_label": "通过邮件分享",
  "lang_source_warn_cancel_dirty": "丢弃未保存的更改？",
  "lang_share_copy_label": "复制链接到剪切板",
  "lang_source_filter": "筛选",
  "lang_share_facebook_label": "分享到 Facebook",
  "lang_tag_change_color_button_title": "更改颜色",
  "lang_experimental": "试验性的",
  "lang_login_invalid_credentials": "错误的用户名或密码",
  "lang_error_check_log_file": "发生了一个错误，请检查日志文件 “{0}”。",
  "lang_login_offline": "离线存储",
  "lang_error_unknown_source": "未知来源 ID：",
  "lang_error_mark_item": "无法标记或取消标记：",
  "lang_error_star_item": "无法星标或取消星标：",
  "lang_error_loading": "加载错误：",
  "lang_article_reading_time": "{0} 分钟阅读时长",
  "lang_sources_loading": "数据源加载中…",
  "lang_no_sources": "数据源未配置。",
  "lang_source_deleting": "正在删除数据源…",
  "lang_source_autotitle_hint": "留空以自动填充标题",
  "lang_source_pwd_placeholder": "没有改变",
  "lang_source_params_loading": "正在加载参数…",
  "lang_source_saving": "正在保存数据源更改…",
  "lang_source_refresh": "刷新此数据源",
  "lang_source_go_to_settings": "在设置中打开",
  "lang_sources_leaving_unsaved_prompt": "保留未保存的数据源更改？",
  "lang_entries_loading": "加载条目中…",
  "lang_login_in_progress": "尝试登录中…",
  "lang_error": "发生了一个错误",
  "lang_error_check_system_logs": "发生了一个错误，请检查你的系统日志。",
  "lang_error_unwritable_logs": "无法写入日志。",
  "lang_streamerror": "没有加载到条目，重新加载？",
  "lang_sources_refreshed": "数据源已刷新",
  "lang_app_update": "selfoss 已被更新，请重新加载",
  "lang_error_add_source": "无法添加源：",
  "lang_error_edit_source": "无法保存源更改：",
  "lang_error_delete_source": "无法删除源：",
  "lang_error_load_tags": "无法加载标签：",
  "lang_error_unknown_tag": "未知的标签：",
  "lang_error_loading_stats": "无法加载统计信息：",
  "lang_error_saving_color": "无法保存新颜色：",
  "lang_error_refreshing_source": "无法刷新数据源：",
  "lang_error_offline_storage": "离线存储出错：{0} ，请尝试重新加载。离线模式已被临时禁用。",
  "lang_error_offline_storage_not_available": "离线存储不可用。确保您的网络浏览器 {0} 支持 IndexedDB API{1}，如果您使用的是基于 Google Chrome 的浏览器，请确保您没有在隐身模式下运行 selfoss。",
  "lang_error_invalid_subsection": "无效的小节：",
  "lang_error_configuration": "无法加载配置。",
  "lang_info_url_copied": "URL 已复制到剪贴板。",
  "lang_error_share_native": "无法分享条目。",
  "lang_article_actions": "动作",
  "lang_reload_list": "重新加载列表"
}
