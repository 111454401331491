"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStateWithDeps = useStateWithDeps;

var _react = require("react");

var _useForceUpdate = require("./use-force-update");

var _depsAreEqual = require("./deps-are-equal");

var _isFunction = require("./is-function");

/**
 * `useState` hook with an additional dependency array that resets
 * the state to the `initialState` param when the dependencies passed
 * in the `deps` array change.
 *
 * @param initialState
 * The state that will be set when the component mounts or the
 * dependencies change.
 *
 * It can also be a function which resolves to the state. If the state
 * is reset due to a change of dependencies, this function will be called with the previous
 * state (`undefined` for the first call upon mount).
 * @param deps Dependencies for this hook that resets the state to `initialState`
 */
function useStateWithDeps(initialState, deps) {
  var isMounted = (0, _react.useRef)(false); // Determine initial state

  var usableInitialState = null;

  if (!isMounted.current) {
    isMounted.current = true;

    if ((0, _isFunction.isFunction)(initialState)) {
      usableInitialState = initialState();
    } else {
      usableInitialState = initialState;
    }
  } // It would be possible to use useState instead of
  // useRef to store the state, however this would
  // trigger re-renders whenever the state is reset due
  // to a change in dependencies. In order to avoid these
  // re-renders, the state is stored in a ref and an
  // update is triggered via forceUpdate below when necessary


  var state = (0, _react.useRef)(usableInitialState); // Check if dependencies have changed

  var prevDeps = (0, _react.useRef)(deps);

  if (!(0, _depsAreEqual.depsAreEqual)(prevDeps.current, deps)) {
    // Update state and deps
    var nextState;

    if ((0, _isFunction.isFunction)(initialState)) {
      nextState = initialState(state.current);
    } else {
      nextState = initialState;
    }

    state.current = nextState;
    prevDeps.current = deps;
  }

  var forceUpdate = (0, _useForceUpdate.useForceUpdate)();
  var updateState = (0, _react.useCallback)(function updateState(newState) {
    var nextState;

    if ((0, _isFunction.isFunction)(newState)) {
      nextState = newState(state.current);
    } else {
      nextState = newState;
    }

    if (!Object.is(state.current, nextState)) {
      state.current = nextState;
      forceUpdate();
    }
  }, []);
  return [state.current, updateState];
}