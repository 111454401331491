{
  "lang_timestamp": "{0,date} {0,time}",
  "lang_article_reading_time": "bacaan {0} menit",
  "lang_article_actions": "Tindakan",
  "lang_close_entry": "Tutup",
  "lang_info_url_copied": "Alamat URL disalin ke papanklip.",
  "lang_error_share_native": "Tidak dapat membagikan item.",
  "lang_error_share_native_abort": "Tidak dapat membagikan item—kemungkinan disana tidak ada target untuk dibagikan, atau Anda membatalkan hal tersebut.",
  "lang_error_configuration": "Tidak dapat memuat konfigurasi.",
  "lang_error_invalid_subsection": "Subbagian tidak valid:",
  "lang_error_offline_storage_not_available": "Penyimpanan offline tidak tersedia. Pastikan peramban web Anda {0} mendukung DB API yang Terindeks {1} dan, jika Anda menggunakan peramban web Goggle Chrome, pastikan Anda tidak menjalankan selfoss di dalam mode incognito (rahasia).",
  "lang_error_offline_storage": "Kesalahan penyimpanan offline: {0}. Memuat ulang mungkin dapat membantu. Menonaktifkan offline untuk saat ini.",
  "lang_error_sync": "Tidak dapat mengsinkronisasi perubahan terakhir dari server:",
  "lang_error_refreshing_source": "Tidak dapat menyegarkan sumber:",
  "lang_error_saving_color": "Tidak dapat menyimpan warna baru:",
  "lang_error_loading_stats": "Tidak dapat memuat statistik navigasi:",
  "lang_error_loading": "Kesalahan dalam memuat:",
  "lang_error_logout": "Tidak dapat keluar:",
  "lang_error_star_item": "Tidak dapat bintangi/batal bintangi item:",
  "lang_error_mark_item": "Tidak dapat menandai/hapus tanda item:",
  "lang_error_mark_items": "Tidak dapat menandai semua item yang terlihat:",
  "lang_error_unknown_source": "ID sumber tidak diketahui:",
  "lang_error_unknown_tag": "Tag yang tidak diketahui:",
  "lang_error_load_tags": "Tidak dapat memuat tag:",
  "lang_error_delete_source": "Tidak dapat menghapus sumber:",
  "lang_error_edit_source": "Tidak dapat menyimpan perubahan sumber:",
  "lang_error_add_source": "Tidak dapat menambahkan sumber:",
  "lang_error_session_expired": "Sesi kadaluarsa",
  "lang_app_reload": "Muat ulang",
  "lang_app_update": "selfoss telah diperbarui, silahkan muat ulang",
  "lang_sources_refreshed": "Sumber telah disegarkan",
  "lang_reload_list": "Muat ulang daftar",
  "lang_streamerror": "Tidak ada item yang dimuat, muat ulang?",
  "lang_error_unwritable_logs": "Tidak dapat menulis log.",
  "lang_error_check_system_logs": "Sebuah kesalahan terjadi, silahkan periksa log sistem Anda.",
  "lang_error_check_log_file": "Sebuah kesalahan terjadi, silahkan periksa berkas log \"{0}\".",
  "lang_error": "Sebuah kesalahan terjadi",
  "lang_experimental": "Eksperimental",
  "lang_no_title": "Tidak ada judul",
  "lang_login_offline": "Penyimpanan offline",
  "lang_login_invalid_credentials": "Nama pengguna/kata sandi salah",
  "lang_login_password": "Kata sandi",
  "lang_login_username": "Nama pengguna",
  "lang_login": "Masuk",
  "lang_more": "Lebih banyak",
  "lang_no_entries": "Tidak ada entri ditemukan",
  "lang_source_refresh": "Segarkan sumber ini",
  "lang_source_last_post": "Postingan terakhir yang dilihat",
  "lang_source_cancel": "Batal",
  "lang_source_saved": "Disimpan",
  "lang_source_save": "Simpan",
  "lang_source_type": "Jenis",
  "lang_source_select": "Silahkan pilih sumber",
  "lang_source_comma": "Dipisahkan oleh koma",
  "lang_source_pwd_placeholder": "Tidak diubah",
  "lang_source_tags": "Tag",
  "lang_source_autotitle_hint": "Biarkan kosong untuk mengambil judul",
  "lang_source_title": "Judul",
  "lang_source_new": "Sumber baru yang belum disimpan",
  "lang_source_warn": "Yakin ingin menghapus sumber ini?",
  "lang_source_delete": "Hapus",
  "lang_source_filter": "Saring",
  "lang_source_edit": "Sunting",
  "lang_source_export": "Ekspor sumber",
  "lang_source_opml": "atau import sebuah berkas OPML",
  "lang_source_add": "Tambahkan sumber",
  "lang_sources": "Sumber",
  "lang_loginbutton": "Masuk",
  "lang_logoutbutton": "Keluar",
  "lang_settingsbutton": "Pengaturan",
  "lang_refreshbutton": "Segarkan sumber",
  "lang_searchremove": "Sembunyikan pencarian",
  "lang_searchbutton": "Cari",
  "lang_search_label": "Cari istilah",
  "lang_share_copy_label": "Salin tautan ke papanklip",
  "lang_share_mail_label": "Bagikan menggunakan email",
  "lang_share_wordpress_label": "Bagikan di WordPress",
  "lang_share_wallabag_label": "Simpan ke Wallabag",
  "lang_share_pocket_label": "Simpan ke Pocket",
  "lang_share_facebook_label": "Bagikan di Facebook",
  "lang_share_twitter_label": "Bagikan di Twitter",
  "lang_share_diaspora_label": "Bagikan di Diaspora",
  "lang_share_native_label": "Bagikan",
  "lang_load_img": "Tampilkan gambar",
  "lang_open_window": "Buka",
  "lang_next": "Selanjutnya",
  "lang_unmark": "Tandai sebagai belum dibaca",
  "lang_mark": "Tandai sebagai telah dibaca",
  "lang_unstar": "Batal Bintangi",
  "lang_star": "Bintangi",
  "lang_days": "{0,plural,zero {today},satu {yesterday},lainnya {# days ago}}",
  "lang_seconds": "{0,plural,one {1 second},lainnya {# seconds}} lalu",
  "lang_minutes": "{0,plural,one {1 minute},lainnya {# minutes}} lalu",
  "lang_hours": "{0,plural,one {1 hour},lainnya {# hours}} lalu",
  "lang_alltags": "Semua tag",
  "lang_tags": "Tag",
  "lang_offline_navigation_unavailable": "Menukar tag dan sumber saat ini tidak tersedia dalam mode offline.",
  "lang_offline_count": "Item tersedia secara lokal",
  "lang_online_count": "Item tersedia di server",
  "lang_starred": "Dilihat",
  "lang_unread": "Belum Dibaca",
  "lang_newest": "Terbaru",
  "lang_filter": "Saring",
  "lang_markread": "Tandai sebagai telah dibaca"
}
