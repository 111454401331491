{
  "lang_markread": "Označit jako přečtené",
  "lang_filter": "Zobrazovat",
  "lang_newest": "Nejnovější",
  "lang_unread": "Nepřečtené",
  "lang_starred": "Označené",
  "lang_tags": "Štítky",
  "lang_alltags": "Všechny štítky",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "před {0,plural,one {1 hodinou},other {# hodinami}}",
  "lang_minutes": "před {0,plural,one {1 minutou},other {# minutami}}",
  "lang_seconds": "před {0,plural,one {chvílí},other {# sekundami}}",
  "lang_days": "{0,plural,zero {dnes},one {včera},other {před # dny}}",
  "lang_star": "Označit",
  "lang_unstar": "Odznačit",
  "lang_mark": "Označit jako přečtené",
  "lang_unmark": "Označit jako nepřečtené",
  "lang_load_img": "Zobrazit obrázky",
  "lang_open_window": "Otevřít",
  "lang_next": "Další",
  "lang_search_label": "Vyhledávaný text",
  "lang_searchbutton": "Hledat",
  "lang_searchremove": "Skrýt hledání",
  "lang_refreshbutton": "Obnovit zdroje",
  "lang_settingsbutton": "Nastavení",
  "lang_logoutbutton": "Odhlásit se",
  "lang_loginbutton": "Přihlásit se",
  "lang_sources": "Zdroje",
  "lang_sources_loading": "Načítání zdrojů…",
  "lang_no_sources": "Nejsou nastaveny žádné zdroje.",
  "lang_source_add": "Přidat zdroj",
  "lang_source_opml": "nebo nahrát opml soubor",
  "lang_source_export": "Export zdrojů",
  "lang_source_edit": "Upravit",
  "lang_source_filter": "Filtr",
  "lang_source_menu": "Více akcí",
  "lang_source_browse": "Prohlížet",
  "lang_source_delete": "Smazat",
  "lang_source_warn": "Opravdu chcete tento zdroj smazat?",
  "lang_source_deleting": "Mazání zdroje…",
  "lang_source_warn_cancel_dirty": "Opravdu chcete zahodit neuložené změny?",
  "lang_source_new": "Nový neuložený zdroj",
  "lang_source_title": "Název",
  "lang_source_autotitle_hint": "Pro automatické vyplnění ponechte prázdné",
  "lang_source_tags": "Štítky",
  "lang_source_pwd_placeholder": "Beze změny",
  "lang_source_comma": "Oddělené čárkou",
  "lang_source_select": "Vyberte prosím zdroj",
  "lang_source_params_loading": "Načítání parametrů…",
  "lang_source_type": "Typ",
  "lang_source_save": "Uložit",
  "lang_source_cancel": "Zrušit",
  "lang_source_saving": "Ukládání změn zdroje…",
  "lang_source_saved": "Uloženo",
  "lang_source_last_post": "Poslední příspěvek spatřen",
  "lang_source_refresh": "Obnovit tento zdroj",
  "lang_source_go_to_settings": "Otevřít v nastavení",
  "lang_sources_leaving_unsaved_prompt": "Na stránce jsou neuložené změny. Opravdu chcete opustit nastavení?",
  "lang_tag_change_color_button_title": "Změnit barvu",
  "lang_entries_loading": "Načítání záznamů…",
  "lang_no_entries": "Žádný záznam",
  "lang_more": "Více",
  "lang_login": "Přihlásit",
  "lang_login_in_progress": "Přihlašování…",
  "lang_login_username": "Jméno",
  "lang_login_password": "Heslo",
  "lang_login_invalid_credentials": "Špatné jméno nebo heslo",
  "lang_login_error_generic": "Pokus o přihlášení selhal: {errorMessage}",
  "lang_no_title": "Bez titulku",
  "lang_error": "Nastala chyba",
  "lang_error_unauthorized": "Pro pokračování se prosím {link_begin}přihlaste{link_end}.",
  "lang_error_check_log_file": "Nastala chyba, zkontrolujte prosím záznamy v souboru „{0}“.",
  "lang_error_check_system_logs": "Nastala chyba, zkontrolujte prosím záznamy v operačním systému.",
  "lang_error_unwritable_logs": "Nebylo možné zaznamenat chybu.",
  "lang_streamerror": "Nenačteny žádné položky, zkusit znovu?",
  "lang_reload_list": "Znovu načíst seznam",
  "lang_sources_refreshed": "Zdroje byly obnoveny",
  "lang_error_session_expired": "Vaše sezení vypršelo",
  "lang_error_add_source": "Zdroj nemohl být přidán:",
  "lang_error_delete_source": "Zdroj nemohl být smazán:",
  "lang_error_load_tags": "Štítky nemohly být načteny:",
  "lang_error_unknown_tag": "Neznámý štítek:",
  "lang_error_unknown_source": "Neznámé id zdroje:",
  "lang_error_mark_items": "Nelze označit všechny viditelné zdroje:",
  "lang_error_mark_item": "Nelze označit položku za (ne)přečtenou:",
  "lang_error_star_item": "Nelze o(d)značit položku hvězdičkou:",
  "lang_error_logout": "Odhlášení se nezdařilo:",
  "lang_error_loading": "Chyba načítání:",
  "lang_error_loading_stats": "Načítání statistik se nezdařilo:",
  "lang_error_saving_color": "Nelze změnit barvu:",
  "lang_error_refreshing_source": "Nelze obnovit zdroje:",
  "lang_error_sync": "Synchronizace změn ze serveru selhala:",
  "lang_error_invalid_subsection": "Podsekce neexistuje:",
  "lang_online_count": "Položky dostupné na serveru",
  "lang_offline_count": "Položky jsou dostupné v prohlížeči",
  "lang_offline_navigation_unavailable": "Offline režim zatím neumožňuje přepínat mezi štítky a zdroji.",
  "lang_login_offline": "Ukládat data v prohlížeči",
  "lang_app_update": "selfoss byl aktualizován, prosím znovu načtěte stránku",
  "lang_app_reload": "Znovu načíst",
  "lang_error_offline_storage": "Chyba lokálního úložiště: {0}. Možná pomůže znovunačtení stránky. Prozatím bude ukládání vypnuto.",
  "lang_article_actions": "Akce",
  "lang_error_configuration": "Nelze načíst konfiguraci.",
  "lang_share_native_label": "Sdílet",
  "lang_share_diaspora_label": "Sdílet na Diaspoře",
  "lang_share_twitter_label": "Sdílet na Twitteru",
  "lang_share_facebook_label": "Sdílet na Facebooku",
  "lang_share_pocket_label": "Uložit do Pocketu",
  "lang_share_wallabag_label": "Uložit do Wallabagu",
  "lang_share_wordpress_label": "Sdílet na WordPressu",
  "lang_share_mail_label": "Sdílet e-mailem",
  "lang_share_copy_label": "Zkopírovat odkaz do schránky",
  "lang_info_url_copied": "URL zkopírována do schránky.",
  "lang_error_share_native_abort": "Nelze sdílet položku: buď váš prohlížeč nepodporuje sdílení odkazů, nebo jste sdílení zrušili.",
  "lang_error_share_native": "Nelze sdílet položku.",
  "lang_close_entry": "Zavřít",
  "lang_article_reading_time": "{0} min čtení",
  "lang_error_offline_storage_not_available": "Offline úložiště není dostupné. Ujistěte se, že váš prohlížeč {0}podporuje rozhraní IndexedDB{1} a, pokud používáte prohlížeč založený na Google Chrome, že nespouštíte selfoss v režimu anonymního prohlížení.",
  "lang_error_edit_source": "Nelze uložit změny zdroje:",
  "lang_experimental": "Experiment"
}
