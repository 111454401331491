{
  "lang_markread": "Märgi loetuks",
  "lang_newest": "Uued",
  "lang_unread": "Mitteloetud",
  "lang_starred": "Tärniga",
  "lang_tags": "Sildid",
  "lang_alltags": "Kõik sildid",
  "lang_timestamp": "lisatud {0,date} kell {0,time}",
  "lang_hours": "lisatud {0,plural,one {1 tund},other {# tundi}} tagasi",
  "lang_minutes": "lisatud {0,plural,one {1 minut},other {# minutit}} tagasi",
  "lang_seconds": "lisatud {0,plural,one {1 sekund},other {# sekundit}} tagasi",
  "lang_star": "tärniga",
  "lang_unstar": "tärnita",
  "lang_mark": "märgi loetuks",
  "lang_unmark": "märgi mitteloetuks",
  "lang_load_img": "pildid",
  "lang_open_window": "ava",
  "lang_searchbutton": "otsi",
  "lang_sources": "Tellimused",
  "lang_source_add": "Lisa tellimus",
  "lang_source_opml": "või impordi opml-fail või Google Reader",
  "lang_source_export": "ekspordi tellimused",
  "lang_source_edit": "toimeta",
  "lang_source_delete": "kustuta",
  "lang_source_warn": "Kas tõesti kustutada tellimus?",
  "lang_source_new": "uus salvestamata tellimus",
  "lang_source_title": "Nimi",
  "lang_source_tags": "Sildid",
  "lang_source_comma": "komaga eraldatud",
  "lang_source_select": "Tellimuse allika liik",
  "lang_source_type": "Liik",
  "lang_source_save": "Salvesta",
  "lang_source_cancel": "loobu",
  "lang_source_saved": "salvestatud",
  "lang_no_entries": "Ühtki sündmust ei leitud",
  "lang_more": "rohkem",
  "lang_login": "logi sisse",
  "lang_login_username": "Kasutajanimi",
  "lang_login_password": "Parool",
  "lang_no_title": "Nimeta"
}
