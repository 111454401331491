{
  "lang_share_mail_label": "Compartilhar usando correio eletrônico",
  "lang_error": "um erro ocorreu",
  "lang_no_title": "sem título",
  "lang_login_password": "palavra-passe",
  "lang_login_username": "utilizador",
  "lang_login": "entrar",
  "lang_more": "mais",
  "lang_no_entries": "nenhum registo encontrado",
  "lang_source_saved": "gravado",
  "lang_source_cancel": "cancelar",
  "lang_source_save": "gravar",
  "lang_source_type": "Tipo",
  "lang_source_select": "por favor, selecione a fonte",
  "lang_source_comma": "separado por vírgula",
  "lang_source_tags": "Marcadores",
  "lang_source_title": "Título",
  "lang_source_new": "nova fonte (não salva)",
  "lang_source_warn": "realmente deletar esta fonte?",
  "lang_source_delete": "deletar",
  "lang_source_filter": "Filtro",
  "lang_source_edit": "editar",
  "lang_source_export": "exportar fontes",
  "lang_source_opml": "ou importar ficheiro opml ou google reader",
  "lang_source_add": "adicionar fonte",
  "lang_sources": "Fontes",
  "lang_loginbutton": "entrar",
  "lang_logoutbutton": "sair",
  "lang_settingsbutton": "configurações",
  "lang_refreshbutton": "atualizar fontes",
  "lang_searchbutton": "procurar",
  "lang_next": "próximo",
  "lang_open_window": "abrir",
  "lang_load_img": "carregar Imagens",
  "lang_unmark": "marcar como não lido",
  "lang_mark": "marcar como lido",
  "lang_unstar": "remover estrela",
  "lang_star": "marcar com estrela",
  "lang_seconds": "{0,plural,one {1 segundo},other {# segundos}} atrás",
  "lang_minutes": "{0,plural,one {1 minuto},other {# minutos}} atrás",
  "lang_hours": "{0,plural,one {1 hora},other {# horas}} atrás",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_alltags": "todos os marcadores",
  "lang_tags": "Marcadores",
  "lang_starred": "com estrela",
  "lang_unread": "não lidos",
  "lang_newest": "novos",
  "lang_markread": "marcar como lido",
  "lang_source_last_post": "Última postagem vista",
  "lang_source_menu": "Mais ações",
  "lang_offline_navigation_unavailable": "A troca de etiquetas e fontes não está atualmente disponível em modo offline.",
  "lang_offline_count": "Artigos disponíveis localmente",
  "lang_searchremove": "Ocultar busca",
  "lang_share_diaspora_label": "Compartilhar na Diáspora",
  "lang_share_facebook_label": "Compartilhar no Facebook",
  "lang_share_pocket_label": "Salvar no Pocket",
  "lang_share_wallabag_label": "Salvar no Wallabag",
  "lang_share_copy_label": "Copiar link para a área de transferência",
  "lang_source_refresh": "Atualizar esta fonte",
  "lang_share_native_label": "Compartilhar",
  "lang_search_label": "Termo de pesquisa",
  "lang_sources_leaving_unsaved_prompt": "Deixar configurações com alterações de fonte não salvas?",
  "lang_online_count": "Itens disponíveis no servidor",
  "lang_share_wordpress_label": "Compartilhar no WordPress",
  "lang_source_autotitle_hint": "Deixe vazio para buscar título",
  "lang_source_browse": "Navegar",
  "lang_source_warn_cancel_dirty": "Descartar alterações não salvas?",
  "lang_filter": "Filtro",
  "lang_share_twitter_label": "Compartilhe no Twitter",
  "lang_tag_change_color_button_title": "Alterar a cor",
  "lang_sources_loading": "Carregando fontes…",
  "lang_no_sources": "Nenhuma fonte configurada.",
  "lang_source_deleting": "Excluindo fonte…",
  "lang_source_pwd_placeholder": "Não alterado",
  "lang_source_params_loading": "Carregando parâmetros…",
  "lang_source_saving": "Salvar mudanças de fonte…",
  "lang_source_go_to_settings": "Abrir nas configurações",
  "lang_entries_loading": "Carregando entradas…",
  "lang_login_in_progress": "Tentando fazer login…"
}
