import * as React from "react";
export const useMotionPreference = () => {
    const [reduceMotion, setReduceMotion] = React.useState(false);
    React.useEffect(() => {
        var _a;
        const mediaQuery = (_a = window.matchMedia) === null || _a === void 0 ? void 0 : _a.call(window, "(prefers-reduced-motion: reduce)");
        setReduceMotion(mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.matches);
        const listener = () => setReduceMotion(mediaQuery.matches);
        mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.addEventListener("change", listener);
        return () => mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.removeEventListener("change", listener);
    }, []);
    return reduceMotion;
};
