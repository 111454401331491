{
  "lang_markread": "全部標記為已讀",
  "lang_newest": "最新條目",
  "lang_unread": "未讀條目",
  "lang_starred": "加星標的條目",
  "lang_tags": "標籤",
  "lang_alltags": "所有標籤",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 小時},other {# 小時}}前",
  "lang_minutes": "{0,plural,one {1 分鐘},other {# 分鐘}}前",
  "lang_seconds": "{0,plural,one {1 秒},other {# 秒}}前",
  "lang_days": "{0,plural,zero {今天},one {昨天},other {# 天前}}",
  "lang_star": "加星標",
  "lang_unstar": "取消星標",
  "lang_mark": "標記為已讀",
  "lang_unmark": "標記為未讀",
  "lang_load_img": "載入圖片",
  "lang_open_window": "打開",
  "lang_searchbutton": "搜索",
  "lang_sources": "源",
  "lang_source_add": "添加源",
  "lang_source_opml": "或匯入 OPML 文件/Google 閱讀器訂閱",
  "lang_source_export": "匯出源",
  "lang_source_edit": "編輯",
  "lang_source_delete": "刪除",
  "lang_source_warn": "確認要刪除該源?",
  "lang_source_new": "新的源",
  "lang_source_title": "標題",
  "lang_source_tags": "標籤",
  "lang_source_comma": "以逗號分隔",
  "lang_source_select": "請選擇源",
  "lang_source_type": "類型",
  "lang_source_save": "儲存",
  "lang_source_cancel": "取消",
  "lang_source_saved": "已儲存",
  "lang_source_last_post": "更新於 ",
  "lang_no_entries": "沒有條目",
  "lang_more": "更多",
  "lang_login": "登入",
  "lang_login_username": "帳號",
  "lang_login_password": "密碼",
  "lang_no_title": "無標題"
}
