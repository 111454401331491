{
  "lang_markread": "Tutte lette",
  "lang_newest": "Recenti",
  "lang_unread": "Non lette",
  "lang_starred": "Contrassegnate",
  "lang_tags": "Etichette",
  "lang_alltags": "Tutte le etichette",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_days": "{0,plural,one {1 ora},other {# ore}} fa",
  "lang_minutes": "{0,plural,one {1 minuto},other {# minuti}} fa",
  "lang_seconds": "{0,plural,one {1 secondo},other {# secondi}} fa",
  "lang_star": "Contrassegna",
  "lang_unstar": "Togli contrassegno",
  "lang_mark": "Segna come letta",
  "lang_unmark": "Segna come non letta",
  "lang_load_img": "Caricare le immagini",
  "lang_open_window": "Apri",
  "lang_searchbutton": "Ricerca",
  "lang_sources": "Fonti",
  "lang_source_add": "Aggiungi una fonte",
  "lang_source_opml": "o importa un file OPML / Google reader",
  "lang_source_export": "Esporta fonti",
  "lang_source_edit": "modifica",
  "lang_source_delete": "cancella",
  "lang_source_warn": "Vuoi veramente eliminare questa fonte?",
  "lang_source_new": "Nuova fonte",
  "lang_source_title": "Titolo",
  "lang_source_tags": "Etichette",
  "lang_source_comma": "separate da una virgola",
  "lang_source_select": "seleziona il tipo di fonte",
  "lang_source_type": "Tipo",
  "lang_source_save": "salva",
  "lang_source_cancel": "annulla",
  "lang_no_entries": "Nessuna pubblicazione",
  "lang_more": "Caricare altre pubblicazioni",
  "lang_login": "Accedi",
  "lang_login_username": "Nome utente",
  "lang_login_password": "Password",
  "lang_no_title": "Senza titolo",
  "lang_error_star_item": "Non è possibile contrassegnare/togliere il contrassegno per l'elemento:",
  "lang_error_mark_item": "Impossibile segnare l'elemento come letto/non letto:",
  "lang_article_actions": "Azioni",
  "lang_close_entry": "Chiudi",
  "lang_info_url_copied": "URL copiato negli appunti.",
  "lang_error_share_native": "Impossibile condividere l'elemento.",
  "lang_error_share_native_abort": "Impossibile condividere l'articolo – non ci sono obiettivi di condivisione o l'hai cancellato.",
  "lang_error_configuration": "Impossibile caricare la configurazione.",
  "lang_error_invalid_subsection": "Sottosezione non valida:",
  "lang_error_offline_storage": "Errore di archiviazione offline: {0}. Il ricaricamento può aiutare. Disabilitazione la modalità offline per ora.",
  "lang_error_sync": "Impossibile sincronizzare le ultime modifiche dal server:",
  "lang_error_refreshing_source": "Impossibile aggiornare le fonti:",
  "lang_error_saving_color": "Impossibile salvare un nuovo colore:",
  "lang_error_loading_stats": "Impossibile caricare le statistiche di navigazione:",
  "lang_error_loading": "Errore di caricamento:",
  "lang_error_logout": "Impossibile uscire:",
  "lang_error_mark_items": "Impossibile contrassegnare tutti gli elementi visibili:",
  "lang_error_unknown_source": "Identificazione della fonte sconosciuta:",
  "lang_error_unknown_tag": "Etichetta sconosciuta:",
  "lang_error_load_tags": "Impossibile caricare le etichette:",
  "lang_error_delete_source": "Impossibile eliminare l'origine:",
  "lang_error_add_source": "Impossibile aggiungere la fonte:",
  "lang_error_session_expired": "Sessione scaduta",
  "lang_app_reload": "Ricarica",
  "lang_app_update": "selfoss è stato aggiornato, si prega di ricaricare",
  "lang_sources_refreshed": "Fonti aggiornate",
  "lang_reload_list": "Ricarica l'elenco",
  "lang_streamerror": "Nessun elemento caricato, ricaricare?",
  "lang_error": "Si è verificato un errore",
  "lang_source_refresh": "Aggiorna questa fonte",
  "lang_source_last_post": "Ultimo elemento visto",
  "lang_source_saved": "Salvato",
  "lang_source_pwd_placeholder": "Non cambiato",
  "lang_source_autotitle_hint": "Lascia vuoto per recuperare il titolo",
  "lang_source_filter": "Filtro",
  "lang_loginbutton": "Accedi",
  "lang_logoutbutton": "Esci",
  "lang_settingsbutton": "Impostazioni",
  "lang_refreshbutton": "Aggiorna le fonti",
  "lang_searchremove": "Nascondi la ricerca",
  "lang_search_label": "Termine di ricerca",
  "lang_share_copy_label": "Copia il collegamento negli appunti",
  "lang_share_wordpress_label": "Condividi su WordPress",
  "lang_share_wallabag_label": "Salva in Wallabag",
  "lang_share_pocket_label": "Salva in Pocket",
  "lang_share_facebook_label": "Condividi su Facebook",
  "lang_share_twitter_label": "Condividi su Twitter",
  "lang_share_diaspora_label": "Condividi su Diaspora",
  "lang_share_native_label": "Condividi",
  "lang_next": "Avanti",
  "lang_hours": "{0,plural,one {1 ora},other {# ore}} fa",
  "lang_offline_count": "Elementi disponibili localmente",
  "lang_online_count": "Articoli disponibili sul server",
  "lang_filter": "Filtra",
  "lang_share_mail_label": "Condividi via posta elettronica",
  "lang_login_offline": "Archiviazione offline",
  "lang_login_invalid_credentials": "Nome utente o password errato",
  "lang_error_offline_storage_not_available": "Non è disponibile memoria offline. Assicurati che il tuo browser web {0} supporti IdexedDB API {1} e che tu non stia utilizzando la modalità incognito su browser basati su Google Chrome.",
  "lang_error_edit_source": "Impossibile salvare le modifiche alla fonte:",
  "lang_error_unwritable_logs": "Impossibile scrivere i log.",
  "lang_error_check_system_logs": "Si è verificato un errore, controlla i tuoi log di sistema.",
  "lang_error_check_log_file": "Si è verificato un errore, controlla il file di log «{0}».",
  "lang_offline_navigation_unavailable": "Al momento non è possibile modificare etichette e fonti nella modalità fuori linea.",
  "lang_article_reading_time": "{0} min letti",
  "lang_experimental": "Sperimentale"
}
