{
  "lang_markread": "Marquer comme lu",
  "lang_filter": "Filtrer",
  "lang_newest": "Le plus récent",
  "lang_unread": "Non lu",
  "lang_starred": "Favoris",
  "lang_online_count": "Sur le serveur",
  "lang_offline_count": "En local",
  "lang_tags": "Étiquettes",
  "lang_alltags": "Toutes les étiquettes",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "il y a {0,plural,one {1 heure},other {# heures}}",
  "lang_minutes": "il y a {0,plural,one {1 minute},other {# minutes}}",
  "lang_seconds": "il y a {0,plural,one {1 seconde},other {# secondes}}",
  "lang_days": "{0,plural,zero {aujourd’hui},one {hier},other {il y a # jours}}",
  "lang_star": "Ajouter aux favoris",
  "lang_unstar": "Supprimer des favoris",
  "lang_mark": "Marquer comme lu",
  "lang_unmark": "Marquer comme non lu",
  "lang_load_img": "Afficher les images",
  "lang_open_window": "Ouvrir",
  "lang_next": "Suivant",
  "lang_searchbutton": "Rechercher",
  "lang_searchremove": "Masquer le champ de recherche",
  "lang_refreshbutton": "Actualiser les sources",
  "lang_settingsbutton": "Paramètres",
  "lang_logoutbutton": "Se déconnecter",
  "lang_loginbutton": "Se connecter",
  "lang_sources": "Sources",
  "lang_source_add": "Ajouter une source",
  "lang_source_opml": "ou importer un fichier OPML ou Google Reader",
  "lang_source_export": "Exporter les sources",
  "lang_source_edit": "Modifier",
  "lang_source_filter": "Filtre",
  "lang_source_delete": "Supprimer",
  "lang_source_warn": "Voulez-vous vraiment supprimer cette source ?",
  "lang_source_new": "Nouvelle source non sauvegardée",
  "lang_source_title": "Titre",
  "lang_source_autotitle_hint": "Laisser vide pour récupérer le titre",
  "lang_source_tags": "Étiquettes",
  "lang_source_pwd_placeholder": "Mot de passe inchangé",
  "lang_source_comma": "Séparés par une virgule",
  "lang_source_select": "Veuillez sélectionner une source",
  "lang_source_type": "Type",
  "lang_source_save": "Enregistrer",
  "lang_source_cancel": "Annuler",
  "lang_source_saved": "Enregistré",
  "lang_source_last_post": "Dernier élément vu",
  "lang_source_refresh": "Rafraîchir cette source",
  "lang_no_entries": "Aucun article à consulter",
  "lang_more": "Plus",
  "lang_login": "Connexion",
  "lang_login_username": "Identifiant",
  "lang_login_password": "Mot de passe",
  "lang_login_invalid_credentials": "Nom d’utilisateur/Mot de passe incorrect",
  "lang_login_offline": "Stockage hors-ligne",
  "lang_no_title": "Sans titre",
  "lang_error": "Une erreur est survenue",
  "lang_streamerror": "Une erreur s’est produite lors du chargement de la liste, essayer à nouveau ?",
  "lang_reload_list": "Recharger la liste",
  "lang_sources_refreshed": "Sources actualisées",
  "lang_app_update": "selfoss a été mis à jour, merci de recharger",
  "lang_app_reload": "Recharger",
  "lang_error_session_expired": "Session expirée",
  "lang_error_add_source": "Impossible d'ajouter la source :",
  "lang_error_delete_source": "Impossible de supprimer la source :",
  "lang_error_load_tags": "Impossible de charger les étiquettes :",
  "lang_error_unknown_tag": "Tag inconnu :",
  "lang_error_unknown_source": "Identifiant de source inconnu :",
  "lang_error_mark_items": "Impossible de marquer tous les éléments visibles :",
  "lang_error_mark_item": "Erreur lors du marquage lu/non lu :",
  "lang_error_star_item": "Erreur lors de l’ajouter/suppression du favori :",
  "lang_error_logout": "Impossible de se déconnecter :",
  "lang_error_loading": "Erreur de chargement :",
  "lang_error_loading_stats": "Impossible de charger les statistiques de navigation :",
  "lang_error_saving_color": "Impossible de sauvegarder une nouvelle couleur :",
  "lang_error_refreshing_source": "Impossible de rafraîchir les sources :",
  "lang_error_sync": "Impossible de synchroniser les dernières modifications du serveur :",
  "lang_error_offline_storage": "Erreur stockage hors-ligne : {0}. Recharger la page pourrait aider. Le mode hors-ligne est désactivé pour le moment.",
  "lang_error_invalid_subsection": "Sous-section erronée :",
  "lang_search_label": "Terme de recherche",
  "lang_article_actions": "Actions",
  "lang_error_configuration": "Impossible de charger la configuration.",
  "lang_info_url_copied": "URL copiée dans le presse-papiers.",
  "lang_share_native_label": "Partager",
  "lang_share_diaspora_label": "Partager sur Diaspora",
  "lang_share_twitter_label": "Partager sur Twitter",
  "lang_share_facebook_label": "Partager sur Facebook",
  "lang_share_pocket_label": "Enregistrer dans Pocket",
  "lang_share_wallabag_label": "Enregistrer dans Wallabag",
  "lang_share_wordpress_label": "Partager sur WordPress",
  "lang_share_mail_label": "Partager par courriel",
  "lang_share_copy_label": "Copier le lien dans le presse-papiers",
  "lang_error_share_native_abort": "Impossible de partager l'élément, soit il n'y a pas de cible, soit vous avez annulé le partage.",
  "lang_error_share_native": "Impossible de partager l'élément.",
  "lang_close_entry": "Fermer",
  "lang_error_unwritable_logs": "Impossible d'écrire les fichiers de journalisation.",
  "lang_error_check_system_logs": "Une erreur est survenue. Veuillez consulter votre journalisation système.",
  "lang_error_check_log_file": "Une erreur est survenue. Veuillez consulter le fichier « {0} ».",
  "lang_offline_navigation_unavailable": "Modifier les étiquettes et les sources n'est pas possible hors ligne pour le moment.",
  "lang_error_offline_storage_not_available": "Le stockage hors ligne n’est pas disponible. Assurez-vous que votre navigateur Web {0}prend en charge l’API IndexedDB{1} et, si vous utilisez un navigateur basé sur Google Chrome, que vous n’exécutez pas selfoss en mode de navigation privée.",
  "lang_error_edit_source": "Impossible d'enregistrer les modifications de la source :"
}
